import crudMutations from "@/store/templates/crud/mutations";

export default {
  setTitle(state, title) {
    state.model.title = title;
  },
  setType(state, type) {
    state.model.type = type;
  },
  setColor(state, color) {
    state.model.color = color;
  },
  ...crudMutations
};
